
import { defineComponent, ref } from 'vue';
import SignUpComponent from '@/components/login/SignUpComponent.vue';
import {
  PASSWORD_DOES_NOT_MATCH,
  SignUpFormFields
} from '@/components/login/FormFields';
import { AuthClient } from '@/services/auth';
import { useRouter } from 'vue-router';

export default defineComponent({
  components: { SignUpComponent },
  setup() {
    let error = ref();
    let isLoading = ref(false);
    const router = useRouter();

    function onSignup({
      name,
      email,
      password,
      passwordConfirm
    }: SignUpFormFields) {
      if (password !== passwordConfirm) {
        error.value = PASSWORD_DOES_NOT_MATCH;
        return;
      }
      startSignup({ name, email, password, passwordConfirm });
    }

    async function startSignup(SignUpFormFields: SignUpFormFields) {
      isLoading.value = true;
      error.value = '';
      try {
        // Create new user with email and password through the firebase SDK
        const user = await AuthClient.getInstance().createUserWithEmailAndPassword(
          SignUpFormFields.email,
          SignUpFormFields.password
        );
        if (user) {
          // Update display name
          await user.updateProfile({
            displayName: SignUpFormFields.name,
            photoURL: ''
          });
          await AuthClient.getInstance().sendEmailVerification();
          // nav to confirmation page
          router.push({
            name: 'verification'
          });
        }
      } catch (err) {
        if (err.message) {
          error.value = err.message;
        } else {
          error.value = 'There was an error signing up. Please try again.';
        }
      }
      isLoading.value = false;
    }

    return {
      error,
      isLoading,
      onSignup
    };
  }
});
